@import "../../common/scss/colors";
@import "../../common/scss/spacing";

.footer {
  background-color: $primary-04;
  padding: 3.9375rem 0 1.125rem;
  color: $white;
  text-align: center;

  // font-family: "Lato", sans-serif;
  &__logo {
    margin: 0 auto;
    width: 130px;
    max-width: 70%;

    img {
      width: 100%;
    }
  }

  &__main {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  &-links__title {
    font-size: 2.0625rem;
    font-weight: bold;
    margin-bottom: $spacing-05;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__list {
    list-style-type: none;
    padding: 0;

    li {
      margin-bottom: $spacing-04;
    }
  }

  &__link {
    color: $white;
    text-decoration: none;
    font-size: 1.4375rem;

    &:hover {
      color: $white;
      opacity: 0.7;
    }
  }

  &__icon {
    width: 2.4375rem;
  }

  //   @media (min-width: 767px) {
  //     &__main {
  //       grid-template-columns: repeat(2, 1fr);
  //     }
  //   }
  @media (max-width: 991px) {
    &__logo {
      margin-bottom: $spacing-09;
    }

    &-links__title {
      justify-content: center;
    }
  }

  @media (min-width: 992px) {
    text-align: start;

    &__main {
      grid-template-columns: repeat(4, 1fr);
      // grid-auto-flow: dense; /* NEW */
    }
  }
}

@media (min-width: 992px) {
  .rtl-footer {
    .footer__main {
      grid-auto-flow: column;
      direction: rtl;
      /* reversed horizontal */
      font-family: GE-Dinar-One, "Lato", sans-serif;
    }
  }
}

.onProgramDetails-con {
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: GE-Dinar-One, "Lato", sans-serif;
}

.onProgramDetails-con .icon {
  color: white;
  margin-right: 20px;
  margin-left: 20px;
}