@import "~video-react/styles/scss/video-react"; // or import scss
@import "../../common/scss/colors.scss";
@import "../../common/scss/utilities-classes.scss";
.video-react {
  &-paused:not(.video-react-has-started) {
    @include TsRounded;
    @include TsRoundedNotTopRight;
    overflow: hidden;
  }

  .video-react-big-play-button {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    background-color: $primary-04;
    border: 2px solid $primary-04;
    &::before {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2.5rem;
    }
  }
  &:hover {
    .video-react-big-play-button {
      border: 1px solid $primary-04;
      background-color: #ea8db970;
    }
  }
  &-poster {
    background-size: cover !important;
    @include TsRounded;
    @include TsRoundedNotTopRight;
  }
}

// .video-react .video-react-poster {
//   @include TsRounded;
//   @include TsRoundedNotTopRight;
// }
